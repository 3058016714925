
import { defineComponent, ref } from "vue"
import FilterBaseV2, { IApiDataEmit, IClientDataEmit } from "@/layout/header/components/FilterBaseV2.vue"
import { useLoaderStore } from "@/store/LoaderStore"

export default defineComponent({
  name: "FilterCompetitorsListPage",
  components: {
    FilterBaseV2,
  },
  setup(props, { emit }) {
    /** Variables */
    const isActiveModal  = ref(false)
    const isCloseModal = ref(false)
    const loaderStore = useLoaderStore()

    /** Functions */
    function handleResetFields(emit: IClientDataEmit) { }

    async function handleExecuteSearch(emit: IApiDataEmit) {
      isCloseModal.value = false
      loaderStore.open()
      if(!emit.loading) {
        isCloseModal.value = true
        loaderStore.close()
      }
      if(emit.error)
        loaderStore.close()
    }

    /** Life Cycles */

    return {
      isActiveModal,
      isCloseModal,
      handleResetFields,
      handleExecuteSearch,
    }
  },
})
